<template>
    <div v-if="!isLoading">
        <b-card no-body>
            <div class="card-header" v-show="tabsMinimized">
                <b-button v-b-tooltip.hover
                          title="Visa flikar"
                          class="border-gray-light"
                          size="sm"
                          @click="tabsMinimized = false;"
                          variant="light">
                    <font-awesome-icon icon="bars" />
                </b-button>
                {{titles[active]}}
            </div>
            <b-tabs :class="{minimized: tabsMinimized}"
                    class="main-tab"
                    vertical
                    pills
                    card
                    v-model="active"
                    @input="onTabChange"
                    @activate-tab="onTabActivate">
                <!--Översikt-->
                <b-tab lazy
                       :disabled="buildingId <= 0"
                       v-if="hasPermission('overview')">
                    <template #title>
                        Översikt
                    </template>
                    <BuildingOverview :id="buildingId" />
                </b-tab>
                <!--Grunddata-->
                <b-tab lazy
                       v-if="hasPermission('view') || buildingId === 0">
                    <template #title>
                        Grunddata
                    </template>
                    <BuildingBasicData v-model="buildingId" :buildingName="buildingName" />
                </b-tab>
                <!--Positioner-->
                <b-tab lazy
                       :disabled="buildingId <= 0"
                       v-if="hasPermission('positions')">
                    <template #title>
                        Positioner
                    </template>
                    <BuildingPositions :building-id="buildingId" :buildingName="buildingName" />
                </b-tab>
                <!--Scheman-->
                <b-tab lazy
                       :disabled="buildingId <= 0"
                       v-if="hasPermission('schedules')">
                    <template #title>
                        Schema egenkontroller
                    </template>
                    <ListBuildingSchedules :building-id="buildingId" :buildingName="buildingName" />
                </b-tab>
                <!--Egenkontroller-->
                <b-tab lazy
                       :disabled="buildingId <= 0"
                       v-if="hasPermission('controls')">
                    <template #title>
                        Egenkontroller
                    </template>
                    <ListBuildingControls :building-id="buildingId" :buildingName="buildingName" />
                </b-tab>
                <!--Åtgärdsplan-->
                <b-tab lazy
                       :disabled="buildingId <= 0"
                       v-if="hasPermission('lacks')">
                    <template #title>
                        Åtgärdsplan
                    </template>
                    <ListBuildingPositionLacks :building-id="buildingId" :buildingName="buildingName" />
                </b-tab>

                <!--Separator-->
                <b-tab :title-link-class="['text-center']"
                       disabled>
                    <template #title>
                        <hr class="my-0"
                            style="width:97%;" />
                    </template>
                </b-tab>

                <!--Go to buildings-->
                <b-tab @click.prevent="$router.push({ name: 'manage-building-list' })">
                    <template #title>
                        Gå till fastigheter
                    </template>
                </b-tab>

                <!--Minimera-->
                <b-tab :title-link-class="['text-center']">
                    <template #title>
                        <div style="display:inline-block;"
                             v-b-tooltip.hover.bottom
                             title="Minimera flikar">
                            <button class="btn border-gray-light btn-light">
                                <font-awesome-icon icon="chevron-left" />
                            </button>
                        </div>
                    </template>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>
<script>
    import { get } from '@/helpers/api';
    import { mapState, mapActions } from 'vuex';
    import BuildingBasicData from '@/views/manage/building/building-tabs/BuildingBasicData';
    import BuildingPositions from '@/views/manage/building/building-tabs/BuildingPositions';
    import BuildingOverview from '@/views/manage/building/building-tabs/BuildingOverview';
    import ListBuildingSchedules from '@/views/manage/building/building-tabs/ListBuildingSchedules';
    import ListBuildingControls from '@/views/manage/building/building-tabs/ListBuildingControls';
    import ListBuildingPositionLacks from '@/views/manage/building/building-tabs/ListBuildingPositionLacks';

    export default {
        name: 'Building',
        components: {
            BuildingBasicData,
            BuildingPositions,
            BuildingOverview,
            ListBuildingSchedules,
            ListBuildingControls,
            ListBuildingPositionLacks
        },
        computed: {
            ...mapState({
                selectedCustomer: (state) => state.user.selectedCustomer,
                selectedBuilding: (state) => state.user.selectedBuilding
            }),
            routeTab() {
                return this.$route.params.tab;
            },
            isOnMobile() {
                return window.innerWidth <= 767;
            }
        },
        data() {
            return {
                tabsMinimized: false,
                isLoading: true,
                buildingName: '',
                tabs: ['overview', 'view', 'positions', 'schedules', 'controls', 'lacks'],
                titles: ['Översikt', 'Grunddata', 'Positioner', 'Schema', 'Egenkontroller', 'Åtgärdsplan'],
                permissions: [],
                active: 0,
                buildingId: parseInt(this.$route.params.id)
            };
        },
        watch: {
            buildingId: {
                async handler(newValue, oldValue) {                    
                    this.$nextTick(() => { if (this.selectedCustomer != null) document.title = `${this.selectedCustomer.name} / Fastigheter - SeBrA`});
                    if (oldValue != undefined && oldValue !== newValue) {
                        this.$router.replace({
                            name: 'manage-building',
                            params: { id: newValue }
                        });
                        await this.getViewPermissions();
                    }
                },
                immediate: true

            },
            selectedCustomer(newValue, oldValue) {
                if (newValue.id !== oldValue.id)
                    this.$router.push({ name: 'manage-building-list' })
            },
            $route(to) {
                if (to.params.tab)
                    this.setTabIndexByRoute();
            }
        },
        methods: {
            ...mapActions('user', ['setBuilding']),
            onTabActivate(newTabIndex, prevTabIndex, bvEvent) {
                if (bvEvent.vueTarget.tabs.length === newTabIndex + 1) {
                    bvEvent.preventDefault();
                    this.tabsMinimized = true;
                }
            },
            hasPermission(view) {
                if (this.buildingId == 0)
                    return true;

                return this.permissions[view];
            },
            onTabChange(index) {
                var name = this.getTabNameByIndex(index);
                if (this.$route.params.tab === name)
                    return;

                this.$router.push({
                    name: 'manage-building',
                    params: {
                        id: this.buildingId,
                        tab: name
                    }
                });

                // If mobile.
                if (this.isOnMobile)
                    this.tabsMinimized = true;
            },
            getTabNameByIndex(index) {
                return this.tabs[index];
            },
            getTabIndexByRoute() {
                // Do not have permissions.
                if (!this.hasPermission(this.routeTab)) {
                    this.$router.replace({
                        name: 'manage-building',
                        params: { tab: 'overview' }
                    });
                    return 0;
                }
                var index = this.tabs.findIndex(x => x === this.routeTab);

                this.$nextTick(() => {
                    document.title = `${this.selectedCustomer.name} / ${this.buildingName} / ${this.titles[index]} - SeBrA`
                });
                return index;
            },
            setTabIndexByRoute() {
                this.$nextTick(() => this.active = this.getTabIndexByRoute());
            },
            async getViewPermissions() {
                this.isLoading = true;
                // Get permissions.
                await get('UserBuildingPermission', `GetViewPermissionsById/${this.buildingId}`)
                    .then(x => {
                        this.buildingName = x.data.buildingName
                        this.permissions = x.data.permissions;

                        // Show tabs according to permissions.
                        this.tabs = [];
                        Object.keys(this.permissions).forEach(key => {
                            if (this.permissions[key])
                                this.tabs.push(key);
                        });
                    })
                    .then(() => {
                        this.isLoading = false;
                        // Set tab index.
                        this.setTabIndexByRoute();
                    })
                    .catch((x) => {
                        alert('Fastigheten gick inte ladda');
                        this.$router.push({
                            name: 'manage-building-list'
                        });
                    });
            }
        },
        async mounted() {
            if (this.buildingId === 0) {
                this.isLoading = false;
                this.active = 1;
            }                
            else
                this.getViewPermissions();

            await this.setBuilding(this.buildingId ?? null);

            // If mobile.
            if (this.isOnMobile)
                this.tabsMinimized = true;
        }
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }

    .main-tab {
        min-height: 96vh;
    }
</style>

<style>
    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
        background-color: #007bff;
    }

    .minimized > .col-auto {
        display: none;
    }

    @media (max-width: 767.98px) {
        .main-tab > .col-auto {
            position: fixed;
            z-index: 11;
            box-shadow: 3px 3px 6px #afafaf;
            border: 1px solid #b7b7b7;
        }

            .main-tab > .col-auto > [role=tablist] {
                background-color: #f7f7f7 !important;
            }
    }
</style>
